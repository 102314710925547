import React, { useState, useEffect } from 'react';

import { bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';


import appSettings from '../../../../config/settings';

import SectionContainer from '../SectionContainer';
import css from './SectionHero.module.css';


// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionHero = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
    isLandingPage,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const landingPage = isLandingPage ?? false;

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);

  const onClickHandleGMSignUp = () => {
    localStorage.setItem("isGM", "true");
    location.href = "signup";
  }

  const onClickHandlePlayerSignUp = () => {
    location.href = "signup";
  }

  const sharetribeSdk = require('sharetribe-flex-sdk');
  const sdk = sharetribeSdk.createInstance({
    clientId: appSettings.sdk.clientId
  });

  const [renderLoginButtons, setRenderLoginButtons] = useState(false);

  useEffect(() => {
    sdk.currentUser.show()
      .then(res => {
        setRenderLoginButtons(false);
      })
      .catch(error => {
        setRenderLoginButtons(true);
        console.warn("User is not logged in yet can't show profile!");
      });
  }, []);

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={classNames(rootClassName || css.root)}
      appearance={appearance}
      options={fieldOptions}
      isLandingHero={landingPage}
    >
      <div className={css.badge}>Alpha</div>
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
          {
            renderLoginButtons ? <div className={css.button_hero_container}>
              <button onClick={() => onClickHandleGMSignUp()} className={css.button_hero}>JOIN AS A GM</button>
              <button onClick={() => onClickHandlePlayerSignUp()} className={css.button_hero}>JOIN AS A PLAYER</button>
            </div> : <></>
          }
        </header>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionHero.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionHero.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionHero;
